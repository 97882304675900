<template>
  <div class="dailyPaper">
    <div>
      <div>
        <div style="display: flex; justify-content: space-between">
          <div class="cadr">
            <div style="text-align: center; font-size: 17px">
              本年度累计产量
            </div>
            <div style="text-align: center; margin-top: 20px; font-size: 16px">
              {{ produt.rawMonthProduct }}(万吨)
            </div>
          </div>
          <div class="cadr" style="margin-top: 5px">
            <div style="text-align: center; font-size: 17px">
              本年度累计销量
            </div>
            <div style="text-align: center; margin-top: 20px; font-size: 16px">
              {{ produt.marketMonthProduct }}(万吨)
            </div>
          </div>
          <div class="cadr" style="margin-top: 5px">
            <div style="text-align: center; font-size: 17px">
              本年度铁路公路合计外运累计量
            </div>
            <div style="text-align: center; margin-top: 5px; font-size: 16px">
              {{ produt.transferCount }}(万吨)
            </div>
          </div>
        </div>
        <div
          class="flex"
          style="
            margin-top: 20px;
            border-top: 1px solid #ccc;
            padding-top: 10px;
          "
        >
          <div class="title">煤炭产运销趋势图</div>
          <div>
            <el-date-picker
              v-model="value1"
              type="monthrange"
              range-separator="至"
              start-placeholder="开始月份"
              end-placeholder="结束月份"
              value-format="yyyy-MM-dd"
              @change="valueChange"
            >
            </el-date-picker>
          </div>
        </div>
      </div>

      <div>
        <div id="echart" style="width: 900px; height: 400px"></div>
      </div>
      <div class="content">
        <div class="lines"></div>
        <div class="flex">
          <div class="cont_title">生产煤矿月报数据录入</div>
          <div style="text-align: right; display: flex">
            <div style="margin-right: 10px">
              <el-date-picker
                v-model="value2"
                type="monthrange"
                range-separator="至"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                value-format="yyyy-MM-dd"
                @change="valueChanges"
              >
              </el-date-picker>
            </div>
            <el-button type="primary" @click="addPrimary">录入</el-button>
            <el-button @click="exportSubmit">
              <a href="javascript:void(0)" style="color: #333">导出</a>
            </el-button>
          </div>
        </div>

        <div style="margin-top: 20px">
          <el-table :data="tableData" style="width: 100%" border>
            <el-table-column label="操作" width="120px" align="center">
              <template slot-scope="scope">
                <el-button
                  :disabled="scope.row.auditStatus == 0"
                  @click.native.prevent="deleteRow(scope.row, tableData)"
                  type="text"
                  size="small"
                >
                  编辑
                </el-button>
                <el-button
                  :disabled="scope.row.auditStatus == null"
                  @click.native.prevent="modifyRecord(scope.row)"
                  type="text"
                  size="small"
                >
                  详情
                </el-button>
              </template>
            </el-table-column>
            <el-table-column label="日期" prop="declareDate" align="center">
            </el-table-column>
            <el-table-column
              prop="rawMonthProduct"
              label="原煤产量(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="marketMonthProduct"
              label="本月煤炭销量(万吨)"
              align="center"
            >
            </el-table-column>
            <!-- <el-table-column prop="rawProductCount" label="累计原煤产量(万吨)" align="center">
                        </el-table-column>
                        <el-table-column prop="marketProductCount" label="累计煤炭销量(万吨)" align="center">
                        </el-table-column> -->
            <el-table-column
              prop="inventory"
              label="月末库存(万吨)"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="remark" label="状态" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.auditStatus === null">正常</span>
                <span v-if="scope.row.auditStatus == 0">待审核</span>
                <span v-if="scope.row.auditStatus == 1">审核通过</span>
                <span v-if="scope.row.auditStatus == 2">审核拒绝</span>
              </template>
            </el-table-column>
          </el-table>
          <div style="text-align: right; margin-top: 10px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page.sync="currentPage1"
              :page-size="10"
              layout="total, prev, pager, next"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
      title=""
      destroy-on-close
      :visible.sync="dialogVisible"
      width="800px"
    >
      <div>
        <div
          style="
            border-bottom: 1px solid #ccc;
            padding-bottom: 10px;
            text-align: center;
          "
        >
          <div style="font-size: 19px">月报数据录入</div>
        </div>
        <div style="margin-top: 10px">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="130px"
            class="demo-ruleForm"
          >
            <div style="display: flex">
              <el-form-item label="日期" prop="declareDate">
                <el-date-picker
                  v-model="ruleForm.declareDate"
                  type="month"
                  placeholder="选择月"
                  value-format="yyyy-MM"
                  @change="pickerChanges"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="本月产量(万吨)" prop="rawMonthProduct">
                <el-input
                  v-model="ruleForm.rawMonthProduct"
                  placeholder="请输入本月产量"
                ></el-input>
              </el-form-item>
            </div>
            <div style="display: flex">
              <el-form-item label="本月销量(万吨)" prop="marketMonthProduct">
                <el-input
                  v-model="ruleForm.marketMonthProduct"
                  placeholder="请输入本月销量"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="月末库存(万吨)"
                prop="inventory"
                style="margin-left: 25px"
              >
                <el-input
                  v-model="ruleForm.inventory"
                  placeholder="请输入月末库存"
                ></el-input>
              </el-form-item>
            </div>

            <div style="display: flex">
              <el-form-item label="平均煤价(元/吨)" prop="averagePrice">
                <el-input
                  v-model="ruleForm.averagePrice"
                  placeholder="请输入平均煤价"
                ></el-input>
              </el-form-item>
              <el-form-item label=""> </el-form-item>
            </div>
            <div
              style="padding: 10px 0; text-align: center; background: #f6f6f6"
            >
              外调煤
            </div>
            <div class="flexs" style="margin-top: 20px">
              <div>
                <span style="font-size: 18px; font-weight: bold; color: black"
                  >铁路</span
                >
                <i
                  class="el-icon-circle-plus"
                  style="font-size: 20px"
                  @click="addDomains"
                ></i>
                <i
                  class="el-icon-remove"
                  style="font-size: 20px"
                  @click="deteleList1"
                ></i>
              </div>
            </div>
            <div
              class="flexs"
              style="margin-top: 20px"
              v-for="(items, index) in railway"
              :key="items.id"
            >
              <el-form-item label="方向" prop="directionId">
                <el-cascader
                  v-model="items.directionId"
                  :options="citys"
                  placeholder="请选择方向"
                  :props="{
                    value: 'id',
                    label: 'name',
                    checkStrictly: true,
                  }"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="本月运量(万吨)" prop="total">
                <el-input
                  v-model="items.total"
                  placeholder="请输入本月运量"
                ></el-input>
              </el-form-item>
            </div>
            <div class="flexs" style="margin-top: 20px">
              <div>
                <span style="font-size: 18px; font-weight: bold; color: black"
                  >公路</span
                >
                <i
                  class="el-icon-circle-plus"
                  style="font-size: 20px"
                  @click="addDomain"
                ></i>
                <i
                  class="el-icon-remove"
                  style="font-size: 20px"
                  @click="deteleList"
                ></i>
              </div>
            </div>
            <div
              class="flexs"
              style="margin-top: 20px"
              v-for="(item, index) in highway"
              :key="index"
            >
              <el-form-item label="方向" prop="directionId">
                <el-cascader
                  v-model="item.directionId"
                  :options="citys"
                  placeholder="请选择方向"
                  :props="{
                    value: 'id',
                    label: 'name',
                    checkStrictly: true,
                  }"
                  @change="echange"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="本月运量(万吨)" prop="total">
                <el-input
                  v-model="item.total"
                  placeholder="请输入本月运量"
                ></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="AddDialogVisible('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as echarts from "echarts";
import citys from "@/assets/json/citys";
import {
  findReportMonthList,
  addAndEditReportMonth,
  findReportMonthChart,
  findReportMonthChartHitherto,
  exportReportMonthList,
  sysReportMonth,
} from "@/api/mining.js";
import { getTreeV2 } from "@/api/Medium.js";
export default {
  data() {
    return {
      value2: "",
      citys: citys,
      ruleForm: {},
      config: {
        pageNum: 1,
        pageSize: 10,
        declareEndDate: null,
        declareStartDate: null,
      },
      dialogVisible: false,
      tableData: [],
      rules: {
        declareDate: [
          { required: true, message: "请选择时间", trigger: "change" },
        ],
        rawMonthProduct: [
          { required: true, message: "请输入本月产量", trigger: "blur" },
        ],
        marketMonthProduct: [
          { required: true, message: "请输入本月销量", trigger: "blur" },
        ],
        inventory: [
          { required: true, message: "请输入月末库存", trigger: "blur" },
        ],
        averagePrice: [
          { required: true, message: "请输入平均煤价", trigger: "blur" },
        ],
      },
      value1: "",
      charts: "",
      opinionData: [],
      currentPage1: 1,
      total: 0,
      startTimeStamp: "",
      endTimeStamp: "",
      xdata: [],
      highway: [
        {
          directionId: "",
          total: "",
          accumulated: "",
          price: "",
        },
      ],
      railway: [
        {
          directionId: "",
          total: "",
          accumulated: "",
          price: "",
        },
      ],
      echartsConfig: {
        pageNum: 1,
        pageSize: 10,
        declareStartDate: null,
        declareEndDate: null,
      },
      produt: {
        rawMonthProduct: 0,
        marketMonthProduct: 0,
        transferCount: 0,
      },
    };
  },

  mounted() {
    this.getlist();
    this.getecharts();
    this.getTree();
  },
  methods: {
    modifyRecord(data) {
      this.$emit("getDetail", data.id);
    },
    getTree() {
      getTreeV2().then((res) => {
        if (res.code == 0) {
          this.citys = this.deleteChildren(res.data);
        }
      });
    },
    echange(e) {
      console.log(e, this.highway);
    },
    pickerChanges(val) {
      console.log(val);
      let _this = this;
      if (val) {
        this.ruleForm.monthDate = val;
      } else {
        this.ruleForm.monthDate = null;
      }
    },
    deleteRow(e, t) {
      if (e.auditStatus == 0) {
        return;
      }
      console.log(e, "eeeeee");
      this.ruleForm = {};
      this.railway = e.railway;
      this.highway = e.highway;
      this.ruleForm = e;
      this.dialogVisible = true;
    },
    valueChange(e) {
      if (e) {
        this.echartsConfig.declareEndDate = e[1];
        this.echartsConfig.declareStartDate = e[0];
      } else {
        this.echartsConfig.declareEndDate = null;
        this.echartsConfig.declareStartDate = null;
      }
      this.getecharts();
    },
    valueChanges(e) {
      if (e) {
        this.config.declareEndDate = e[1];
        this.config.declareStartDate = e[0];
      } else {
        this.config.declareEndDate = null;
        this.config.declareStartDate = null;
      }
      this.getlist();
    },
    getecharts() {
      this.xdata = [];
      this.opinionData = [];
      findReportMonthChart(this.echartsConfig).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let list = res.data;
          let list1 = [];
          let list2 = [];
          let list3 = [];
          list.forEach((item) => {
            list1.push(item.marketWeekProduct);
            list2.push(item.rawWeekProduct);
            list3.push(item.transferCount);
            this.xdata.push(item.key);
          });
          this.opinionData = [
            {
              name: "煤炭销量",
              type: "line",
              stack: "Total",
              data: list1,
            },
            {
              name: "原煤产量",
              type: "line",
              stack: "Total",
              data: list2,
            },
            {
              name: "外调煤总量",
              type: "line",
              stack: "Total",
              data: list3,
            },
          ];
          this.$nextTick(function () {
            this.drawLine("echart");
          });
        }
      });
    },
    AddDialogVisible(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.highway, this.railway);
          this.highway.forEach((item) => {
            if (item.directionId instanceof Array) {
              item.directionId = item.directionId[item.directionId.length - 1];
            }
          });
          this.railway.forEach((item) => {
            if (item.directionId instanceof Array) {
              item.directionId = item.directionId[item.directionId.length - 1];
            }
          });
          this.ruleForm.highway = this.highway;
          this.ruleForm.railway = this.railway;
          console.log(this.ruleForm);
          addAndEditReportMonth(this.ruleForm).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "添加成功",
                type: "success",
              });
              this.dialogVisible = false;
              this.getlist();
              this.getecharts();
            } else {
              this.$message.error(res.message);
            }
          });
        }
      });
    },
    getlist() {
      findReportMonthChartHitherto().then((res) => {
        if (res.code == 0) {
          if (res.data) {
            this.produt = res.data;
          }
        }
      });
      findReportMonthList(this.config).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    deleteChildren(arr) {
      let childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].children) {
          let newlist = childs[i].children;
          for (let j = newlist.length; j--; j > 0) {
            delete newlist[j].children;
          }
        }
      }
      return arr;
    },
    addDomain() {
      let obj = {
        directionId: "",
        total: "",
        accumulated: "",
        price: "",
      };
      this.highway.push(obj);
    },
    addDomains() {
      let obj = {
        directionId: "",
        total: "",
        accumulated: "",
        price: "",
      };
      this.railway.push(obj);
    },
    deteleList1() {
      if (this.railway.length > 1) {
        this.railway.pop();
      }
    },
    deteleList() {
      if (this.highway.length > 1) {
        this.highway.pop();
      }
    },
    exportSubmit() {
      exportReportMonthList(this.config).then((res) => {
        var elink = document.createElement("a");
        elink.download = "月报数据.xls";
        elink.style.display = "none";
        var blob = new Blob([res], { type: "application/vnd.ms-excel" });
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        document.body.removeChild(elink);
      });
    },
    addPrimary() {
      this.ruleForm = {};
      (this.highway = [
        {
          directionId: "",
          total: "",
          accumulated: "",
          price: "",
        },
      ]),
        (this.railway = [
          {
            directionId: "",
            total: "",
            accumulated: "",
            price: "",
          },
        ]),
        (this.dialogVisible = true);
    },
    pickerChange(val) {
      if (val) {
        let timeStamp = val.getTime(); //标准时间转为时间戳，毫秒级别
        this.startTimeStamp = this.fun(timeStamp - 24 * 60 * 60 * 1000); //开始时间
        this.endTimeStamp = this.fun(timeStamp + 24 * 60 * 60 * 1000 * 5); //结束时间
        console.log(this.startTimeStamp, this.endTimeStamp);
      }
    },
    fun(unixtimestamp) {
      var unixtimestamp = new Date(unixtimestamp);
      var year = 1900 + unixtimestamp.getYear();
      var month = "0" + (unixtimestamp.getMonth() + 1);
      var date = "0" + unixtimestamp.getDate();
      var hour = "0" + unixtimestamp.getHours();
      var minute = "0" + unixtimestamp.getMinutes();
      var second = "0" + unixtimestamp.getSeconds();
      return (
        year +
        "-" +
        month.substring(month.length - 2, month.length) +
        "-" +
        date.substring(date.length - 2, date.length)
      );
    },
    handleSizeChange(e) {
      console.log(e, "111111111");
    },
    handleCurrentChange(e) {
      this.config.pageNum = e;
      this.getlist();
      console.log(e, "页数");
    },
    // 绘制柱状图
    drawLine(id) {
      this.charts = echarts.init(document.getElementById(id));
      this.charts.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
          backgroundColor: "rgba(0, 0, 0,0.5)",
          borderColor: "#1198B9",
          textStyle: {
            fontSize: 14,
            color: "#FFFFFF",
          },
          formatter: function (params) {
            let relVal = null;
            for (let i = 0; i < params.length; i++) {
              relVal +=
                "<br/>" +
                params[i].seriesName +
                ":  " +
                params[i].value +
                "吨;";
            }
            return relVal.slice(9);
          },
        },
        legend: {
          // show: false,
          itemHeight: 11,
          itemWidth: 11,
          icon: "roundRect",
          borderRadius: [5, 5, 5, 5],
          right: 30,
          top: 10,
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        // toolbox: {
        //     feature: {
        //         saveAsImage: {},
        //     },
        // },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: this.xdata,
        },
        yAxis: {
          type: "value",
          name: "万吨",
        },

        series: this.opinionData,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 228px;
}

.dailyPaper {
  padding: 20px;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 23px;
}

.content {
  margin-top: 20px;
}

.cont_title {
  font-size: 23px;
  text-align: left;
}

::v-deep .el-date-editor.el-input__inner {
  width: 300px;
}

::v-deep .el-date-editor .el-range-separator {
  width: 10%;
}

.cadr {
  width: 200px;
  border: 1px solid #ccc;
  padding: 20px 10px;
}

.flexs {
  display: flex;
  justify-content: space-between;
}

.lines {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 10px 0;
}
</style>
